.custom-dropdown {
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 22px;
  border: 1.5px solid transparent;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  background-color: var(--bgSecondary);
}

.custom-dropdown.large {
  width: 200px;
  height: 45px;
}

.custom-dropdown.medium {
  width: 160px;
  height: 45px;
}

.custom-dropdown.small {
  width: 170px;
  height: 36px;
  cursor: pointer;
}

.custom-dropdown:hover {
  border-color: var(--primary);
  color: var(--primary);
}

.ant-dropdown-link > .dropdown_arrow {
  transition: all 0.3s ease-in-out;
}

.ant-dropdown-open {
  border-color: var(--primary);
  border-radius: 20px 20px 0px 0px;
  border-bottom: none;
}

.ant-dropdown-open > .dropdown_arrow {
  transform: rotate(180deg);
}

.dropdown_menu {
  background-color: var(--bgSecondary);
  border: 1.5px solid var(--primary);
  width: inherit;
  border-top: none;
  border-radius: 0px 0px 20px 20px;
  padding: 0px 22px 14px 22px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
}

.dropdown_menu.small {
  margin-top: -6px;
  padding-bottom: 5px !important;
}

.dropdown_menu > a {
  font-size: 16px;
  color: var(--textColor);
  margin-bottom: 5px;
}

.dropdown_menu > a:hover {
  color: var(--primary);
}

.horizontal_line {
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
  margin: 10px 0;
}

.addLocationButton {
  opacity: 75%;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
}

/* these are table headers */

.tableHeaderArea .ant-dropdown-link:hover {
  cursor: pointer;
}
