.teacherReportCol1 {
    width: 40%;
    font-weight: 600;
}

.teacherReportCol2 {
    width: 40%;
    text-align: center;
}

.teacherReportCol3 {
    width: 20%;
    text-align: center;
}