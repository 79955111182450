.modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-body {
  background: var(--bgPrimary);
  border-radius: 25px;
  padding: 40px 40px;
  width: 660px;
}

.modal-button-group {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}
