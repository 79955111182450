.calendarInput {
  background-color: white;
  width: 220px;
}

.calendarButton {
  width: 80px;
  text-align: center;
  margin: 0 5px;
  padding: 2px 0;
  transition: all 0.2s ease-in-out;
}

.calendarButton:hover {
  cursor: pointer;
  color: var(--primaryHover);
}

.calendarContainer {
  position: relative;
}

.calendarSelected {
  background-color: rgba(113, 195, 231, 0.15);
  color: #50b9e8;
  border: 1px solid #50b9e8;
}

.calendarHide {
  display: none;
}

.clearButton {
  color: var(--textDisabled);
  transition: all 0.1s ease-in-out;
}

.clearButton:hover {
  cursor: pointer;
  color: var(--primaryHover);
}

.clearButton.transparent:hover {
  color: transparent !important;
  cursor: default !important;
}

/* Hide prev and next button */
.pika-prev,
.pika-next {
  display: none !important;
}

.triangle-theme .pika-label:after {
  content: ' \25be';
}

.pika-single {
  width: 100%;
}

.pika-single.calendar-theme {
  color: #fff;
  background: #fff;
  border: 1px solid #666;
  border-bottom-color: #999;
}

.calendar-theme .pika-label {
  color: #222;
  background-color: #fff;
}

.calendar-theme .pika-table th {
  color: #aaa;
}

.calendar-theme .pika-button {
  color: #222;
  text-align: center;
  background: #fff;
}

.calendar-theme .pika-week {
  color: #999;
}

.calendar-theme .is-today .pika-button {
  color: #33aaff;
}

.calendar-theme .is-selected .pika-button {
  color: #fff;
  background: #33aaff;
  box-shadow: inset 0 1px 3px #178fe5;
}

.calendar-theme .is-disabled .pika-button {
  color: #999;
  opacity: 0.3;
}

.calendar-theme .pika-button:hover {
  color: #fff !important;
  background: #50b9e8 !important;
}