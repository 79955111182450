.productReportCol1 {
    width: 20%;
    font-weight: 600;
}

.productReportCol2 {
    width: 16%;
    text-align: center;
}

.productReportCol3 {
    width: 16%;
    text-align: center;
}

.productReportCol4 {
    width: 16%;
    text-align: center;
}

.productReportCol5 {
    width: 16%;
    text-align: center;
}

.productReportCol6 {
    width: 16%;
}