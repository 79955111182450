.generalReportCol1 {
    width: 10%;
}

.generalReportCol2 {
    width: 18%;
}

.generalReportCol3 {
    width: 25%;
}

.generalReportCol4 {
    width: 32%;
}

.generalReportCol5 {
    width: 15%;
}