@font-face {
  font-family: 'Bitter';
  src: local('Bitter'),
    url('./assets/fonts/Bitter/static/Bitter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bitter';
  font-weight: medium;
  src: local('Bitter'),
    url('./assets/fonts/Bitter/static/Bitter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  /* font-style: normal; */
  font-weight: 200;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  /* font-style: normal; */
  font-weight: 400;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 400;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  /* font-style: normal; */
  font-weight: 500;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: bold;
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
