.card {
  background-color: var(--bgSecondary);
  border-radius: 20px;
  height: 180px;
  width: 180px;
  padding: 10px 15px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
}

.cardBody {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card.wide {
  height: 180px;
  width: 350px;
}

.cardValue {
  font-size: 32px;
  font-weight: bold;
}

.cardTitle {
  font-weight: 400;
  font-size: 16px;
  color: var(--textSecondary);
}

.cardIcon {
  color: var(--textDisabled);
  margin-top: auto;
}