.passwordInputContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.passwordInput {
  width: 100%;
  border-radius: 5px;
  border: 1.5px solid;
  outline: none;
  padding: 2px 8px;
}

.passwordInput:focus {
  border: 1.5px solid var(--primaryHover);
}

.passwordToggleIcon {
  position: absolute;
  right: 15px;
  transition: all 0.3s ease-in-out;
  color: var(--textDisabled);
}

.passwordToggleIcon:hover {
  cursor: pointer;
  color: var(--textPrimary);
}

.passwordToggleIcon.showing {
  color: var(--primaryHover);
}
