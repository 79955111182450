.dashboardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.dashboardTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--textSecondary);
  margin-bottom: 12px;
}

.dashboardLeft {
  display: flex;
  flex-direction: column;
}

.dashboardRight {
  display: flex;
  flex-direction: column;
}

.cardRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.cardRow > * {
  margin-right: 30px;
}

.cardRow > :last-child {
  margin-right: 0;
}

