.schoolsReportCol1 {
    width: 40%;
    font-weight: 600;
}

.schoolsReportCol2 {
    width: 30%;
    text-align: center;
}

.schoolsReportCol3 {
    width: 30%;
    text-align: center;
}