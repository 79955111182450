/* combobox */

.customCombobox {
  position: relative;
}

.comboboxInput {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 12px;
  font-size: 16px;
}

.comboboxInput.large {
  height: 40px;
}

.comboboxInput.small {
  height: 30px;
  width: 400px;
}

.comboboxInput:focus {
  outline: var(--primaryHover) auto 1.5px;
}

.comboboxButton {
  margin-left: -35px;
  border: none;
  background: none;
  font-size: 1.5em;
  color: var(--textSecondary);
}

.comboboxClearButton {
  margin-left: -50px;
  border: none;
  background: none;
  font-size: 1.5em;
  color: var(--textDisabled);
  transition: all 0.2s ease-in-out;
}

.comboboxClearButton:hover {
  cursor: pointer;
  color: var(--primaryHover);
}

.comboboxClearButton.transparent:hover {
  color: transparent !important;
  cursor: default !important;
}

.comboboxOptions {
  position: absolute;
  max-height: 250px;
  width: 100%;
  background-color: var(--bgSecondary);
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style-type: none;
  margin-top: 10px;
  padding: 5px 0;
  overflow-y: auto;
  z-index: 300;
}

.comboboxOption {
  padding: 10px 12px;
}

.comboboxOption.small {
  padding: 5px 12px;
}

.selectedOption {
  font-weight: 600;
  color: var(--primary);
  display: flex;
  align-items: center;
}

.selectedOption > svg {
  margin-right: 10px;
}

.activeOption {
  background-color: rgba(113, 195, 231, 0.15);
}

.disabledOption {
  color: var(--textDisabled);
  padding: 10px 12px;
}
