.menuButton {
  width: 15vw;
  background: #ffffff69;
  color: #00000069;
  border-width: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
  margin: 0 5px;
  padding: 8px 0;
  transition: all 0.3s ease-in-out;
}

.menuButton:hover {
  background: #dadada69;
}

.menu {
  display: flex;
  width: 100vw;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.menuOptionText {
  font-size: 16px;
}

.dark-button {
  color: var(--textColor) !important;
  background-color: var(--bgSecondary);
  box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.05);
}

.dark-button:hover {
  background-color: var(--bgSecondary);
}
