.NoShowReportCol1 {
    width: 20%;
}

.NoShowReportCol2 {
    width: 25%;
}

.NoShowReportCol3 {
    width: 35%;
}

.NoShowReportCol4 {
    width: 30%;
}